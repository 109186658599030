@use "@angular/material" as mat; //declare above imports
@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$media-primary: mat-palette($mat-pink, 400);
$media-accent: mat-palette($mat-pink, 400);

// The warn palette is optional (defaults to red).
$media-warn: mat-palette($mat-red);

$white: mat-palette($mat-grey, 50);
$black: mat-palette($mat-grey, 900);

// Create the theme object (a Sass map containing all of the palettes).
$ms2-theme: mat-light-theme((color: (primary: $media-primary,
                accent: $media-accent,
                warn: $media-warn ),
            typography: mat.define-typography-config(),
            density: 0));

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
    color: white !important;
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
    box-shadow:
        0px 3px 5px -1px rgba(0, 0, 0, 0.2),
        0px 6px 10px 0px rgba(0, 0, 0, 0.14) !important;
}

.mat-mdc-extended-fab {
    .mdc-button__label {
        line-height: 48px;
    }
}

.mdc-dialog .mdc-dialog__content {
    padding: 0px 20px 0 24px !important;
}

button:focus {
    background-color: #ec407a !important;
    color: white !important;
}

mat-chip-grid .cdk-focused .mat-mdc-chip-remove {
    background-color: transparent !important;
}

.mat-checkbox .mat-checkbox-ripple {
    display: none !important;
}

.mat-mdc-form-field-subscript-wrapper {
    display: none !important;
}

.mat-date-range-input-inner {
    top: -10px !important;
}

.mdc-floating-label {
    color: black !important;
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
    color: black !important;
}

.mat-mdc-form-field-type-mat-date-range-input {
    background-color: white !important;
    border-bottom: none !important;
    height: 54px !important;

    .mat-mdc-floating-label {
        font-weight: 500;
        color: black;
        font-family:
            -apple-system,
            BlinkMacSystemFont,
            Segoe UI,
            Roboto,
            Oxygen-Sans,
            Ubuntu,
            Cantarell,
            Helvetica Neue,
            sans-serif;
    }
}

.container {
    width: 100% !important;
    max-width: 1400px !important;
}

.mat-mdc-dialog-actions {
    padding: 18px 24px !important;
    justify-content: space-between !important;
}

.mat-focussed {
    background-color: white !important;
}

.mat-mdc-dialog-content {
    color: black !important;
}

app-dialog {
    .mat-mdc-dialog-actions {
        padding: 18px 24px 6px !important;
    }
}

mat-radio-button {
    label {
        cursor: pointer;
    }
}

.cdk-overlay-pane {
    width: unset !important;
}

.chip {
    background-color: #d1d1d1 !important;
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($ms2-theme);