@import "materialize-css/dist/css/materialize.min.css";
@import "node_modules/angular-notifier/styles.scss";

.pintura-editor,
pintura-editor {
    --color-background: 35, 35, 35 !important;
    --color-foreground: 255, 255, 255 !important;
}

.fixed-action-btn:hover ul {
    visibility: visible;
}

.fixed-action-btn ul a.btn-floating {
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
}

.fixed-action-btn:hover ul a.btn-floating {
    opacity: 1;
}

.fixed-action-btn-resolution {
    z-index: 51 !important;
}

.fixed-action-btn-cognitive:hover ul {
    visibility: visible;
    display: block;
}

.fixed-action-btn-cognitive ul {
    margin-top: 53px;
    display: none;

    a.btn-floating {
        opacity: 0;
        transition: opacity 0.4s ease-in-out;
        margin-bottom: 5px;
    }
}

.fixed-action-btn-cognitive:hover ul a.btn-floating {
    opacity: 1;
}

.fixed-action-btn-cognitive {
    z-index: 51 !important;
    position: absolute;
    top: 0px;
    right: 3px;
}

mat-form-field input {
    border: none !important;
    margin: 0 !important;
    box-shadow: none !important;
}

.mat-ink-bar {
    background-color: #e94480;
}

.mat-input-container {
    max-height: 62px;
    overflow: hidden;
}

.mat-tab-label {
    min-width: 1px !important;
}

.mat-tab-header {
    margin-bottom: 15px;
}

html {
    width: 100%;
    min-height: 100vh;
}

body {
    width: 100%;
    min-height: 100vh;
    background: #e8e8e8;
}

a,
img,
a:hover {
    outline: 0;
}

.sn-content a {
    color: white !important;
    text-decoration: underline;
}

.sn-title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 !important;
}

.sn-title-img {
    line-height: 0 !important;
}

.sn-title-img span {
    line-height: 48px;
    display: inline-block;
    overflow: hidden;
    height: 48px;
}

.sn-title-img img {
    height: 48px;
    width: 48px;
    margin: 0 10px 0 0;
    display: block;
}

.ms-chip {
    overflow: hidden;
}

.ms-chip .ms-tag-name .material-icons {
    font-size: 13px;
    position: relative;
    top: 2px;
    padding: 0 5px;
}

::-webkit-input-placeholder {
    color: black;
}

:-moz-placeholder {
    color: black;
}

::-moz-placeholder {
    color: black;
}

:-ms-input-placeholder {
    color: black;
}

app-image-upload-page,
app-root {
    min-height: 100%;
}

.cropper-wrap-box,
.cropper-view-box {
    display: none;
}

.cropper-container {
    position: absolute;
    z-index: 5;
    top: 0;
    background-image: none;
}

.cropper-face {
    opacity: 0.2;
}

.cropper-line,
.cropper-point {
    background: #26a69a;
}

.ms-main-image {
    display: block !important;
}

.mat-tooltip {
    transform: none !important;
}

.ms-pink {
    background-color: #e94480;
}

.material-icons.close:before {
    content: "";
}

:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-semi-selected),
.cdk-keyboard-focused
    .mat-calendar-body-active
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-semi-selected),
.cdk-program-focused
    .mat-calendar-body-active
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-semi-selected) {
    background-color: rgba(0, 0, 0, 0.04);
}

:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-semi-selected,
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-semi-selected,
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-semi-selected {
    background-color: #3f51b5;
    color: white;
}

.mat-calendar-body-selected {
    background-color: #3f51b5;
    color: white;
}

.mat-calendar-body-begin-range:not(.mat-calendar-body-end-range) {
    border-radius: 100% 0 0 100%;
    background-color: #e8eaf6;
}

.mat-calendar-body-end-range:not(.mat-calendar-body-begin-range) {
    border-radius: 0 100% 100% 0;
    background-color: #e8eaf6;
}

.mat-calendar-body > tr .mat-calendar-cell-semi-selected ~ .mat-calendar-cell-semi-selected {
    border-radius: 0;
}

.mat-calendar-cell-semi-selected {
    background-color: #e8eaf6;
}

.mat-calendar-table-header-divider {
    display: none;
}

.toggle-button {
    margin-left: 24px;
}

.transparent {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: #71717142 !important;
}

.loader {
    border: 8px solid #ffffff;
    border-radius: 50%;
    border-top: 8px solid #ee6e73;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes flickerAnimation {
    0% {
        opacity: 1;
        width: 120px;
    }

    50% {
        opacity: 0.2;
        width: 60px;
    }

    100% {
        opacity: 1;
        width: 120px;
    }
}

@-o-keyframes flickerAnimation {
    0% {
        opacity: 1;
        width: 120px;
    }

    50% {
        opacity: 0.2;
        width: 60px;
    }

    100% {
        opacity: 1;
        width: 120px;
    }
}

@-moz-keyframes flickerAnimation {
    0% {
        opacity: 1;
        width: 120px;
    }

    50% {
        opacity: 0.2;
        width: 60px;
    }

    100% {
        opacity: 1;
        width: 120px;
    }
}

@-webkit-keyframes flickerAnimation {
    0% {
        opacity: 1;
        width: 120px;
    }

    50% {
        opacity: 0.2;
        width: 60px;
    }

    100% {
        opacity: 1;
        width: 120px;
    }
}
